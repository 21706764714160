a:focus {
	outline: none !important;
}

#navbredcrumbs li {
  background-color: transparent;
  padding: 0px;
}

.dialog-title {
  background-color: #f6f6f6;
  color: black;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #4E4E4E4e;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-searchbox {
  display: flex;
  justify-content: flex-end;
}

.full-width {
  width: 100%;
}

header a:focus {
  outline-offset: -2px;
}

a:focus {
  outline-offset: 0;
}

.paper-box-container {
  box-shadow: 0 0.4em 1em #888f91 !important;
  padding: 0px !important;
}

.margin-center {
  margin: auto;
}

.section-header {
  border: 1px solid #d7d6d8;
  background-color: #f6f6f6;
  color: black;
  padding: 4px;
}

.section-header span {
  font-size: 1.375rem;
}

.titleBar {
  border-bottom: 4px solid #702f8a;
  margin-bottom: 20px !important;
}

.noTitleBar {
  border-top: none !important;
}

.breadcrumb {
  margin: 40px 0 0px 0;
}

.no-box {
  box-shadow: none !important;
}

.user-role-select {
  text-align: left;
}

.header-user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20em;
  margin-top: 3px;
}

.passCriteriaUl {
  margin-top: 3%;
  padding-left: 24px;
}

@supports (-ms-ime-align: auto) {
  .cb-item-list.cb-item-list-rich > ul > li {
    border-bottom: 2px solid #d9d9d9;
  }
}

.alertWidth {
  width: 100%;
  margin: 2em 0 2em 0 !important;
  padding: 1em !important;
}

.alertTitle {
  margin-bottom: 0px !important;
}

.testCard a:hover {
  text-decoration: none !important;
}

.testCard a:hover .testCardTitle {
  text-decoration: underline;
}

.view-practice-test-action button {
  padding: 6px 12px !important;
}

.table-wrapper {
  overflow-x: auto;
}
.table-noRecords {
  height: 100px;
  margin-top: 10px;
  text-align: center;
}
.no-padding {
  padding: 0px;
}
@media screen and (max-width: 750px) {
  .view-practice-test-page .MuiCardHeader-root {
    display: block !important;
  }

  .view-practice-test-page .MuiCardHeader-action {
    margin-top: 8px !important;
  }
}
.change-password-btn .btn-sm {
  padding: 12px 13px;
}
.test-inprogress-tag {
  position: absolute;
  top: -20px;
  font-size: 12px;
  color: black;
}
@media print {
  table,
  th,
  td,
  tbody,
  tr {
    border: 1px solid black !important;
  }
  .action,
  svg , .skipNav{
    display: none !important;
  }
}
.skipNav {
  position: fixed;
  z-index: 1501;
  left: 48%;
  top: -200px;
  color: #702f8a !important;
  background-color: transparent;
  transition: top 1.5s ease-out 200ms;
}
.skipNav:focus {
  top: 1em;
  transition: top 300ms ease-in;
}
.cb-type-article {
  padding: 15px 15px 10px 15px !important;
}
.cb-nav-title {
  font-family: "Roboto Slab Bold", sans-serif;
  font-size: 1.500em;
  line-height: 1.417em;
  padding: 0;
  color: #1e1e1e;
  margin: 0 10px;
}
#userContent label + .MuiInput-formControl {
	margin-top: 0px !important;
}

.MuiPopover-paper {
	overflow: hidden !important;
}

.lv-globalHeader-widget .lv-gh-logo a:focus {
  background-size: 106% 106%;
}

.lv-globalHeader-widget .lv-gh-title .lv-gh-lockup a:focus {
  background-size: 110% 110%;
}

.MuiBadge-badge {
  left:0px
}
#footerComponent a:focus,
#footerComponent a:hover {
  color: #ffffff;
}

/*accessibility changes*/
.Mui-error, .MuiFormHelperText-root, .MuiTypography-colorSecondary {
  color: #e62618 !important;
}

.MuiBadge-colorSecondary {
  background-color: green !important;
}

.MuiTableSortLabel-root:focus, .MuiTableSortLabel-root:hover {
  color: #3f51b5 !important;
  text-decoration: underline;
}

.cb-wizard-content-title {
    font-family: "Roboto Slab Bold",sans-serif !important;
    font-size: 1.3rem !important;
}

.margin-y-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-right-20 {
    margin-right: 20px !important;
}

.item-title-info {
  font-size: small;
  margin:0;
  padding:0;
  line-height: inherit;
  color: #505050;
  font-family:"Roboto Slab Bold",sans-serif;
  padding-left: 12px;
}

.margin-top-5{
  margin-top: 5px !important;
}
.cb-acorn:hover {
  font-size: 20px !important;
}
.cb-acorn {
  font-size: 18px !important;
}
